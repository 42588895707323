*{
  box-sizing: border-box!important;
  
}
main{
  width:100%;
}
html {
  overflow-x: hidden;
}
.container-fluid{
  max-width: 100% !important;
  /*padding-left: 0%!important;
  padding-right: 0%!important;*/
}
#tsparticles{
  position: relative!important;
  
}
main{
  position:relative;
  width: 100%;
  left: 0px !important;
  overflow: hidden;
  /*top:71px;*/
}
.bg-dark{
  width: 100% !important;
  /*transition: all 0.5s;*/
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9)!important;
}
.header{
  position:relative;
  height:70vh;
  
  background-image: url('images/mobile.jpg');
  background-size: cover;
  background-position: center;

}
#tsparticles canvas{
  position:relative;
  z-index: 0!important;
}
.headerText{
  padding:20px;
flex-direction: column;
min-height: 275px;
height: 70vh;
}
.headerimages{
  min-height: 275px;
height: 70vh;
position: relative;
}
.headerText h1{
  color:#fff;
  text-shadow: 2px 2px 2px #2b2b2b;
}
.headerText h2{
  font-family: 'Sofia', cursive;
  font-style: italic;
  color:#b7b7b7;
  text-shadow: 2px 2px 2px #1a1a1a;
}
.header-row{
  width:100%;
  position:absolute;
  top:0;
  left:0;
  height: 70vh;
}
.iphoneimg{
  /*transform:translateY(-20%);*/
  position:absolute;
  top:50%;
  left:22%;
  height:40%;
  z-index: 4;
 
}
.ipadimg{
  transform:translateY(-30%);
  position:absolute;
  top:40%;
  left:23%;
  height:45%;
  z-index: 3;

  
}

.lcdimg{
  transform: translateY(-50%);
  position:absolute;
  top:25%;
  left:30%;
  height:60%;
  z-index: 2;

}
.features{
  padding: 4rem 0 4rem 0;
}
.features h2, .contact h2{
  font-family: 'Sofia', cursive;
  font-style: italic;
  color:#8a8a8a;
}
.contact{
  margin-top: 10rem;
  text-align: center;
}
.contact div{
  margin-top: 1rem;
}
.leftf{
  text-align: right;
}
.featIcon{
  width:40px;
  height:40px;
  
  background-color: #c5c5c5;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
 
}
.featimage{
  max-height: 506px!important;
}
.prescard{
  background-color: #ffffff;

border-radius: 10px;
}
.prescard:hover{
  background-color: #ececec;
  transition:background-color 0.4s ease-in!important; 
  
}
.bi-wifi{
  transform: rotate(90deg)
}
.map{
  
}
footer{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height:20vh;
  background-color: #000;
  color: #FFF;
  font-size: 18pt;
}
.loginForm{
  border:2px solid #ececec;
  border-radius: 30px;
  padding: 40px;
  min-width: 300px;
}